<template lang="html">
  <div class="mx-85">
    <h1 class="heading">🔬 Welcome to the velocity lab</h1>
    <router-link to="/multiplayer">06.14.22 Multiplayer</router-link> <br />
    <router-link to="/perlin-noise">06.29.22 Perlin Noise</router-link><br />
    <router-link to="/inline-notes">06.24.22 Inline Notes</router-link><br />
    <router-link to="/typescale">06.--.22 Typescale</router-link><br />
  </div>
</template>

<script>
export default {};
</script>

<style lang="css" scoped>
a {
  @apply text-charcoal;
}
</style>
